<template>
	<div class="">
		<!--本页切换列表-->
		<!-- <div v-show="!dialogFormVisible">
      <div class="tableCon" v-loading="loading_load" :element-loading-text="$t('i18nn_b954f8829728e9d2')">
        <div class="tableConTable">
          <wTable
            :title="$t('i18nn_a88502dfaa1c0f1e')"
            :data="tableData"
            :header="tableHeader"
            @handleCurrentChange="handleCurrentChange"
            @sortChange="sortChange"
            :noDrop="true"
            :maxHeight="this.$store.state.tableMaxHeight4"
          >
            <div slot="topRight">
              <el-button type="warning" plain icon="el-icon-lock" size="small" @click="OpenFrozenAction()">解冻/冻结</el-button>
              <el-button type="primary" plain icon="el-icon-s-check" size="small" @click="ApplyEnCaAction()">申请企业CA证书</el-button>
              <el-button type="primary" icon="el-icon-check" size="small" @click="AuditPassedAction()">{{$t('i18nn_993c4e2a71557fe7')}}</el-button>
              <el-button type="warning" icon="el-icon-close" size="small" @click="AuditNoPassedAction()">{{$t('i18nn_7fe92f41627d9a70')}}</el-button>
              <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
            </div>
            <div slot="topSubRight">
              <div class="filterCon">
                <el-row type="flex" justify="end">
                  <el-col :span="24">
                    <ul class="filterConList">
                      <li>
                        <span>{{$t('i18nn_60456fdef41cac4c')}}</span>
                        <el-select filterable v-model="filterData.state" :placeholder="$t('i18nn_60456fdef41cac4c')" size="small" style="width: 100px;" @change="initData()">
                          <el-option v-for="item in selectOption.stateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                      </li>
                      <li>
                        <span>{{$t('i18nn_18a470eeff7a2bed')}}</span>
                        <el-select filterable v-model="filterData.auditState" :placeholder="$t('i18nn_18a470eeff7a2bed')" size="small" style="width: 100px;" @change="initData()">
                          <el-option v-for="item in selectOption.auditStateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                      </li>

                      <li>
                        <span>{{$t('i18nn_f6152345aa67d0e5')}}</span>
                        <el-select filterable v-model="filterData.perfectState" :placeholder="$t('i18nn_f6152345aa67d0e5')" size="small" style="width: 100px;" @change="initData()">
                          <el-option v-for="item in selectOption.perfectStateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                      </li>
                      <li>
                        <el-select size="small" style="width: 100px;" v-model="filterData.selectDate" placeholder="">
                          <el-option :label="$t('i18nn_fdc34fd7121f9c48')" :value="'updateTime'"></el-option>
                          <el-option :label="$t('i18nn_8845c7472bacf9fd')" :value="'createTime'"></el-option>
                        </el-select>
                        <el-date-picker
                          v-if="filterData.selectDate == 'updateTime'"
                          v-model="filterData.updateTimes"
                          value-format="yyyy-MM-dd"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="-"
                          start-:placeholder="$t('i18nn_7d2ffc27a09ff792')"
                          end-:placeholder="$t('i18nn_46f7be1133b3e2c6')"
                          :picker-options="pickerOptions2"
                          size="small"
                          @change="initData()"
                        ></el-date-picker>

                        <el-date-picker
                          v-else-if="filterData.selectDate == 'createTime'"
                          v-model="filterData.createTimes"
                          value-format="yyyy-MM-dd"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="-"
                          start-:placeholder="$t('i18nn_7d2ffc27a09ff792')"
                          end-:placeholder="$t('i18nn_46f7be1133b3e2c6')"
                          :picker-options="pickerOptions2"
                          size="small"
                          @change="initData()"
                        ></el-date-picker>
                      </li>
                    </ul>
                    <ul class="filterConList">
                      <li>
                        <span>{{$t('i18nn_0a442b86842d8f5a')}}</span>
                        <el-select
                          filterable
                          clearable
                           size="small"
                          v-model="filterData.level"
                          :placeholder="$t('i18nn_0a442b86842d8f5a')"
                          style="width: 100px;"
                          @change="initData()">

                          <el-option v-for="item in selectOption.levelStarList" :key="item.value" :label="item.label" :value="item.value"></el-option>

                        </el-select>
                      </li>
                      <li>
                        <span>{{$t('i18nn_9ce5ada346da7c39')}}</span>
                        <el-input
                          :placeholder="$t('i18nn_9ce5ada346da7c39')"
                          v-model="filterData.enterpriseName"
                          @keyup.enter.native="initData()"
                          :clearable="true"
                          maxlength="20"
                          size="small"
                          style="width: 200px;"
                        ></el-input>
                      </li>
                      <li>
                        <span>{{$t('i18nn_2ccd11027b844bf2')}}</span>
                        <el-input
                          :placeholder="$t('i18nn_1aa424e1fb1f2313')"
                          v-model="filterData.partnerPhone"
                          @keyup.enter.native="initData()"
                          :clearable="true"
                          maxlength="11"
                          size="small"
                          style="width: 200px;"
                        ></el-input>
                      </li>

                      <li>
                        <span>{{$t('i18nn_7c4924f55ce55589')}}</span>
                        <el-input
                          :placeholder="$t('i18nn_901d401d431913a0')"
                          v-model="filterData.mobile"
                          @keyup.enter.native="initData()"
                          :clearable="true"
                          maxlength="11"
                          size="small"
                          style="width: 200px;"
                        ></el-input>
                      </li>
                      <li><el-button type="primary" @click="initData()" size="small" icon="el-icon-search">{{$t('1e7246dd6ccc5539')}}</el-button></li>
                    </ul>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-table-column slot="fixedLeft" type="index" fixed="left" width="50" align="center" label-class-name="nodra">
              <template slot-scope="scope">
                <span class="tran_box"><el-checkbox v-model="scope.row.selected ? true : false"></el-checkbox></span>
              </template>
            </el-table-column>

            <el-table-column slot="fixedRight" :label="$t('93f5ca01b006206c')" min-width="165px" fixed="right">
              <template slot-scope="scope">
                <el-button @click="openDioalog(scope.row)" type="warning" size="mini" icon="el-icon-edit">{{$t('6267f3aedf895209')}}</el-button>
                <el-button @click="openHistory(scope.row)" type="warning" size="mini">{{$t('i18nn_8bafd5202547e50d')}}</el-button>
              </template>
            </el-table-column>
          </wTable>
        </div>
        <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
      </div>
    </div> -->
		

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_f352f204c0d4ec65')}}</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="warning" plain icon="el-icon-lock" size="small" @click="OpenFrozenAction()">解冻/冻结</el-button>
					<el-button type="primary" plain icon="el-icon-s-check" size="small"
						@click="ApplyEnCaAction()">申请企业CA证书</el-button>
					<el-button type="primary" icon="el-icon-check" size="small" @click="AuditPassedAction()">{{$t('i18nn_993c4e2a71557fe7')}}</el-button>
					<el-button type="warning" icon="el-icon-close" size="small" @click="AuditNoPassedAction()">{{$t('i18nn_7fe92f41627d9a70')}}</el-button>
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>

					<!-- <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button> -->
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<ul class="filterConList">
				<li>
					<span>{{$t('i18nn_60456fdef41cac4c')}}</span>
					<el-select filterable v-model="filterData.state" :placeholder="$t('i18nn_60456fdef41cac4c')" size="small" style="width: 100px;"
						@change="initData()">
						<el-option v-for="item in selectOption.stateList" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</li>
				<li>
					<span>{{$t('i18nn_18a470eeff7a2bed')}}</span>
					<el-select filterable v-model="filterData.auditState" :placeholder="$t('i18nn_18a470eeff7a2bed')" size="small" style="width: 100px;"
						@change="initData()">
						<el-option v-for="item in selectOption.auditStateList" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</li>

				<li>
					<span>{{$t('i18nn_f6152345aa67d0e5')}}</span>
					<el-select filterable v-model="filterData.perfectState" :placeholder="$t('i18nn_f6152345aa67d0e5')" size="small" style="width: 100px;"
						@change="initData()">
						<el-option v-for="item in selectOption.perfectStateList" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</li>
				<li>
					<el-select size="small" style="width: 100px;" v-model="filterData.selectDate" placeholder="">
						<el-option :label="$t('i18nn_fdc34fd7121f9c48')" :value="'updateTime'"></el-option>
						<el-option :label="$t('i18nn_8845c7472bacf9fd')" :value="'createTime'"></el-option>
					</el-select>
					<el-date-picker v-if="filterData.selectDate == 'updateTime'" v-model="filterData.updateTimes"
						value-format="yyyy-MM-dd" type="daterange" align="right" unlink-panels range-separator="-"
						start-:placeholder="$t('i18nn_7d2ffc27a09ff792')" end-:placeholder="$t('i18nn_46f7be1133b3e2c6')" :picker-options="pickerOptions2" size="small"
						@change="initData()"></el-date-picker>

					<el-date-picker v-else-if="filterData.selectDate == 'createTime'" v-model="filterData.createTimes"
						value-format="yyyy-MM-dd" type="daterange" align="right" unlink-panels range-separator="-"
						start-:placeholder="$t('i18nn_7d2ffc27a09ff792')" end-:placeholder="$t('i18nn_46f7be1133b3e2c6')" :picker-options="pickerOptions2" size="small"
						@change="initData()"></el-date-picker>
				</li>
			</ul>
			<ul class="filterConList">
				<li>
					<span>{{$t('i18nn_0a442b86842d8f5a')}}</span>
					<el-select filterable clearable size="small" v-model="filterData.level" :placeholder="$t('i18nn_0a442b86842d8f5a')"
						style="width: 100px;" @change="initData()">

						<el-option v-for="item in selectOption.levelStarList" :key="item.value" :label="item.label"
							:value="item.value"></el-option>

					</el-select>
				</li>
				<li>
					<span>{{$t('i18nn_9ce5ada346da7c39')}}</span>
					<el-input :placeholder="$t('i18nn_9ce5ada346da7c39')" v-model="filterData.enterpriseName" @keyup.enter.native="initData()"
						:clearable="true" maxlength="20" size="small" style="width: 200px;"></el-input>
				</li>
				<li>
					<span>{{$t('i18nn_2ccd11027b844bf2')}}</span>
					<el-input :placeholder="$t('i18nn_1aa424e1fb1f2313')" v-model="filterData.partnerPhone" @keyup.enter.native="initData()"
						:clearable="true" maxlength="11" size="small" style="width: 200px;"></el-input>
				</li>

				<li>
					<span>{{$t('i18nn_7c4924f55ce55589')}}</span>
					<el-input :placeholder="$t('i18nn_901d401d431913a0')" v-model="filterData.mobile" @keyup.enter.native="initData()" :clearable="true"
						maxlength="11" size="small" style="width: 200px;"></el-input>
				</li>
				<li>
					<el-button icon="el-icon-search" size="small" type="primary"
						@click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
				</li>
			</ul>
		</div>


		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
				:height="$store.state.tableMaxHeight2" @selection-change="handleSelectionChange"
				@row-click="handleCurrentChange" style="width: 100%" size="small">
				<el-table-column type="selection" fixed="left" align="center" width="55"></el-table-column>
				<el-table-column type="index" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="mobile" :label="$t('e82c9fff83ec2d91')"></el-table-column>
				<el-table-column prop="enterpriseTag" :label="$t('i18nn_a71629b70679f124')">
					<template slot-scope="scope">
						<div>
							<el-tag v-if="'0'==scope.row.enterpriseTag">{{$t('i18nn_cc68ee555944f23d')}}</el-tag>
							<el-tag type="warning" v-else-if="'1'==scope.row.enterpriseTag">{{$t('i18nn_9793ebd267f312aa')}}</el-tag>
							<el-tag type="info" v-else>{{scope.row.enterpriseTag}}</el-tag>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="enterpriseName" :label="$t('i18nn_9ce5ada346da7c39')"></el-table-column>
				<el-table-column prop="provinceCode" :label="$t('f04d208f84e6c343')"></el-table-column>

				<el-table-column prop="cityCode" :label="$t('e05b5d049b64b040')"></el-table-column>
				<el-table-column prop="enterprisePhone" :label="$t('i18nn_558389543b97cb9b')"></el-table-column>
				<el-table-column prop="linkman" :label="$t('i18nn_699a5577be729dde')"></el-table-column>
				<el-table-column prop="state" :label="$t('i18nn_60456fdef41cac4c')">
				</el-table-column>
				<el-table-column prop="partnerName" :label="$t('i18nn_8c3434f7035f8d40')"></el-table-column>
				<el-table-column prop="partnerPhone" :label="$t('i18nn_dcc52d1264a9722b')"></el-table-column>
				<el-table-column prop="auditState" :label="$t('i18nn_18a470eeff7a2bed')"></el-table-column>

				<el-table-column prop="perfectState" :label="$t('i18nn_f6152345aa67d0e5')"></el-table-column>
				<el-table-column prop="shopState" :label="$t('i18nn_b11cc38d93b92f1c')"></el-table-column>
				<el-table-column prop="level" :label="$t('i18nn_cef78ab0fe758fbd')"></el-table-column>
				<el-table-column prop="enclosureLicense" :label="$t('i18nn_3d5ce01c5f6e820a')">
					<template slot-scope="scope">
						<div>
							<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.enclosureLicense"
								:preview-src-list="tableData.map(itemPre=> { return itemPre.enclosureLicense})">
								<div slot="error" class="image-slot" style="font-size: 40px;"><i
										class="el-icon-picture-outline"></i></div>
							</el-image>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="openAccount" :label="$t('i18nn_0ee39403e4a88684')"></el-table-column>
				<el-table-column prop="certificateAuthorization" :label="$t('i18nn_5acf4b4f6e330dad')"></el-table-column>
				<el-table-column prop="stockCert" :label="$t('i18nn_27ae0ed997c04daa')"></el-table-column>

				<el-table-column prop="taxCert" :label="$t('i18nn_8b2af441b1291795')"></el-table-column>
				<el-table-column prop="branchCode" :label="$t('i18nn_ff2df2d80b2fdce5')"></el-table-column>
				<el-table-column prop="enterpriseEmail" :label="$t('i18nn_20ba48c609b26cfa')"></el-table-column>
				<el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column>

				<el-table-column prop="id" :label="'id'"></el-table-column>


				<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
					<template slot-scope="scope">
						<!-- <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div> -->
						<!-- <div v-else> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
						<!-- <el-button @click="delAction($event, scope.row)" type="danger" size="mini"
							icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button> -->
						<!-- </div> -->
						<div>
							<el-button @click="openDioalog(scope.row)" type="warning" size="mini" icon="el-icon-edit">{{$t('6267f3aedf895209')}}</el-button>
							<el-button @click="openHistory(scope.row)" type="warning" size="mini">{{$t('i18nn_8bafd5202547e50d')}}</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination"
					v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>

		<!-- 历史记录弹窗 -->
		<el-dialog :title="$t('i18nn_8bafd5202547e50d')" :close-on-click-modal="false" :visible.sync="dialogHistoryFormVisible" width="1000px"
			:top="'10px'">
			<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('i18nn_b954f8829728e9d2')">
				<div class="tableConTable">
					<wTable :title="$t('i18nn_c67f820bdcd91a9d')" :data="tableHistoryData" :header="tableHistoryHeader"
						@handleCurrentChange="handleCurrentChange" :noDrop="true">
						<el-table-column slot="fixedLeft" type="index" width="50" fixed="left" align="center"
							label-class-name="nodra"></el-table-column>
					</wTable>
				</div>
				<div class="tableConPagination"><hy-page-pagination :pagination="pagination1"
						v-on:updatePageData="getHistoryPageData(enterpriseHistoryId)"></hy-page-pagination></div>
			</div>
		</el-dialog>

		<!--申请CA证书-->
		<el-dialog title="申请CA证书" :close-on-click-modal="false" :visible.sync="dialogCaFormVisible" :top="'10px'">
			<!-- enterpriseId: "",//企 业Id
        enterpriseName: "",//企业名称
        mobile: "", //法人手机号码
        orgCode: "",//组织机构代码
        email: '',//企业邮箱 -->
			<el-form ref="CaForm" :rules="CaFormRules" :model="CaForm" label-width="200px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">
				<!-- <el-form-item label="手机号：" prop="mobile" >
          <el-input type="text" v-model="form.mobile" maxlength="11" :placeholder="$t('29799f539ee6b906')"></el-input>
        </el-form-item> -->
				<el-form-item label="企业Id：" prop="enterpriseId"><el-input type="text" v-model="CaForm.enterpriseId"
						readonly></el-input></el-form-item>
				<el-form-item label="企业名称:" prop="enterpriseName"><el-input type="text" v-model="CaForm.enterpriseName"
						readonly></el-input></el-form-item>
				<el-form-item label="法人手机号码:" prop="mobile"><el-input type="text"
						v-model="CaForm.mobile"></el-input></el-form-item>
				<el-form-item label="组织机构代码:" prop="orgCode"><el-input type="text"
						v-model="CaForm.orgCode"></el-input></el-form-item>
				<el-form-item label="企业邮箱:" prop=""><el-input type="text" v-model="CaForm.email"></el-input></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogCaFormVisible = false">{{$t('4e9fc68608c60999')}}</el-button>
				<el-button type="primary" @click="applyCASubmitForm('CaForm')">{{$t('ad4345dbaabe1479')}}</el-button>
			</div>
		</el-dialog>

		<!--企业冻结状态-->
		<el-dialog :title="$t('i18nn_d3a2d4f5a3174de0')" :close-on-click-modal="false" :visible.sync="dialogFrozenFormVisible">
			<ul style="padding: 10px 0;">
				<li>企业Id：{{ currentSel.id }}</li>
				<li>企业名称：{{ currentSel.enterpriseName }}</li>
				<li>注册电话：{{ currentSel.mobile }}</li>
				<li>
					当前状态：
					<span v-if="'1' === currentSel.state"><el-tag>{{$t('i18nn_e0199d6ecc3ee81a')}}</el-tag></span>
					<span v-else-if="'2' === currentSel.state"><el-tag type="warning">{{$t('i18nn_8c919e34ddb841f3')}}</el-tag></span>
					<span v-else-if="'3' === currentSel.state"><el-tag type="danger">{{$t('i18nn_15585d51ec229bda')}}</el-tag></span>
					<span v-else>{{$t('i18nn_4e1c0d9b7f56ed80')}}</span>
				</li>
			</ul>

			<div class="" style="text-align: left;">
				<!-- <el-button type="primary" plain @click="dialogFrozenFormVisible = false">{{$t('4e9fc68608c60999')}}</el-button> -->
				<!-- //状态 0封禁 1正常 2一般冻结 3永久冻结 -->
				<el-button type="primary" @click="applyFrozenSubmitForm('1')" v-if="'2' === currentSel.state">{{$t('i18nn_85da697a473926e3')}}</el-button>
				<el-button type="warning" @click="applyFrozenSubmitForm('2')" v-if="'1' === currentSel.state">{{$t('i18nn_8c919e34ddb841f3')}}</el-button>
				<!-- <el-button type="danger" @click="applyFrozenSubmitForm('3')" v-if="'1'===currentSel.state||'2'===currentSel.state">{{$t('i18nn_15585d51ec229bda')}}</el-button> -->
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		name: 'EnterpriseMana',
		//meta信息seo用
		// metaInfo: {
		// 	title: '互易天下-管理后台'
		// },
		data() {
			return {
				tableHeader: [{
						prop: 'mobile',
						label: this.$t('e82c9fff83ec2d91')
					},
					{
						prop: 'enterpriseTag',
						label: this.$t('i18nn_a71629b70679f124'),
						templateType: '5',
						formatter: function(val) {
							let reVal = val;
							// 0-厂家 1-代理商'
							if ('0' === val) {
								reVal = '<span class="el-tag el-tag--primary">厂家</span>';
							} else if ('1' === val) {
								reVal = '<span class="el-tag el-tag--warning">代理商</span>';
							} else {
								// reVal = this.$t('i18nn_4e1c0d9b7f56ed80');
							}
							return reVal;
						}
					},
					{
						prop: 'enterpriseName',
						label: this.$t('i18nn_9ce5ada346da7c39')
					},
					{
						prop: 'provinceCode',
						label: this.$t('f04d208f84e6c343')
					},
					{
						prop: 'cityCode',
						label: this.$t('e05b5d049b64b040')
					},
					{
						prop: 'enterprisePhone',
						label: this.$t('i18nn_558389543b97cb9b')
					},
					{
						prop: 'linkman',
						label: this.$t('i18nn_699a5577be729dde')
					},

					{
						prop: 'state',
						label: this.$t('i18nn_60456fdef41cac4c'),
						templateType: '5',
						formatter: function(val) {
							let reVal = val;
							if ('1' === val) {
								reVal = '<span class="el-tag el-tag--primary">正常</span>';
							} else if ('2' === val) {
								reVal = '<span class="el-tag el-tag--warning">冻结</span>';
							} else if ('3' === val) {
								reVal = '<span class="el-tag el-tag--danger">永久冻结</span>';
							} else {
								// reVal = this.$t('i18nn_4e1c0d9b7f56ed80');
							}
							return reVal;
						}
					},
					{
						prop: 'partnerName',
						label: this.$t('i18nn_8c3434f7035f8d40')
					},
					{
						prop: 'partnerPhone',
						label: this.$t('i18nn_dcc52d1264a9722b')
					},
					{
						prop: 'auditState',
						label: this.$t('i18nn_18a470eeff7a2bed'),
						templateType: '5',
						formatter: function(val) {
							let reVal = val;
							if (this.$t('i18nn_993c4e2a71557fe7') === val) {
								reVal = '<span class="el-tag el-tag--primary">审核通过</span>';
							} else if (this.$t('i18nn_411fdc693cb46984') === val) {
								reVal = '<span class="el-tag el-tag--warning">未审核</span>';
							} else if (this.$t('i18nn_7fe92f41627d9a70') === val) {
								reVal = '<span class="el-tag el-tag--danger">审核不通过</span>';
							} else if (this.$t('i18nn_29fa673774a28f1b') === val) {
								reVal = '<span class="el-tag el-tag--danger">封禁</span>';
							} else {
								// reVal = this.$t('i18nn_4e1c0d9b7f56ed80');
							}
							return reVal;
						}
					},
					{
						prop: 'perfectState',
						label: this.$t('i18nn_f6152345aa67d0e5'),
						templateType: '5',
						formatter: function(val) {
							let reVal = val;
							if (this.$t('i18nn_2b6045f2709c84bb') === val) {
								reVal = '<span class="el-tag el-tag--info">未完善</span>';
							} else if (this.$t('i18nn_03402c5b9fe7cab3') === val) {
								reVal = '<span class="el-tag el-tag--primary">完善通过</span>';
							} else if (this.$t('i18nn_5dcc73d5e4f71512') === val) {
								reVal = '<span class="el-tag el-tag--warning">完善审核中</span>';
							} else if (this.$t('i18nn_1ee1a0e366f2adba') === val) {
								reVal = '<span class="el-tag el-tag--danger">完善未通过</span>';
							} else {
								// reVal = this.$t('i18nn_4e1c0d9b7f56ed80');
							}
							return reVal;
						}
					},
					{
						prop: 'shopState',
						label: this.$t('i18nn_b11cc38d93b92f1c'),
						templateType: '5',
						formatter: function(val) {
							let reVal = val;
							if (this.$t('i18nn_601218d4d572e98a') === val) {
								reVal = '<span class="el-tag el-tag--primary">已认证</span>';
							} else if (this.$t('i18nn_177b191d8e0fa84d') === val) {
								reVal = '<span class="el-tag el-tag--warning">未认证</span>';
							} else if (this.$t('i18nn_26d29891d178b990') === val) {
								reVal = '<span class="el-tag el-tag--danger">认证不通过</span>';
							} else {
								// reVal = this.$t('i18nn_4e1c0d9b7f56ed80');
							}
							return reVal;
						}
					},
					{
						prop: 'level',
						label: this.$t('i18nn_0a442b86842d8f5a')
					},
					{
						prop: 'enclosureLicense',
						label: this.$t('i18nn_3d5ce01c5f6e820a'),
						templateType: '1'
					},
					{
						prop: 'openAccount',
						label: this.$t('i18nn_0ee39403e4a88684'),
						templateType: '1'
					},
					{
						prop: 'certificateAuthorization',
						label: this.$t('i18nn_5acf4b4f6e330dad'),
						templateType: '1'
					},
					{
						prop: 'stockCert',
						label: this.$t('i18nn_27ae0ed997c04daa'),
						templateType: '1'
					},
					{
						prop: 'taxCert',
						label: this.$t('i18nn_8b2af441b1291795'),
						templateType: '1'
					},
					{
						prop: 'branchCode',
						label: this.$t('i18nn_ff2df2d80b2fdce5')
					},
					{
						prop: 'enterpriseEmail',
						label: this.$t('i18nn_20ba48c609b26cfa')
					},
					{
						prop: 'createTime',
						label: this.$t('i18nn_8845c7472bacf9fd'),
						sortable: 'custom'
					},
					{
						prop: 'updateTime',
						label: this.$t('i18nn_fdc34fd7121f9c48'),
						sortable: 'custom'
					},
					{
						prop: 'id',
						label: 'ID'
					}
				],

				userInfo: this.$store.getters.getUserInfo,
				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				// auditState 审核状态  0-认证未通过 1-认证通过  2-认证审核中
				// perfectState 完善状态  0-未完善 1-完善审核中 2-完善通过 3-完善未通过

				selectOption: {
					deptList: [],
					levelStarList: [{
							value: '0',
							label: '0星'
						},
						{
							value: '1',
							label: '1星'
						},
						{
							value: '2',
							label: '2星'
						},
						{
							value: '3',
							label: '3星'
						},
						{
							value: '4',
							label: '4星'
						},
						{
							value: '5',
							label: '5星'
						}
					],
					auditStateList: [{
							value: '',
							label: this.$t('16853bda54120bf1')
						},
						{
							value: '0',
							label: this.$t('i18nn_7fe92f41627d9a70')
						},
						{
							value: '1',
							label: this.$t('i18nn_993c4e2a71557fe7')
						},
						{
							value: '2',
							label: this.$t('i18nn_411fdc693cb46984')
						},
						{
							value: '5',
							label: this.$t('i18nn_29fa673774a28f1b')
						}
					],
					stateList: [{
							value: '',
							label: this.$t('16853bda54120bf1')
						},
						{
							value: '1',
							label: this.$t('i18nn_e0199d6ecc3ee81a')
						},
						{
							value: '2',
							label: this.$t('i18nn_8c919e34ddb841f3')
						},
						{
							value: '3',
							label: this.$t('i18nn_15585d51ec229bda')
						}
					],
					perfectStateList: [{
							value: '',
							label: this.$t('16853bda54120bf1')
						},
						{
							value: '0',
							label: this.$t('i18nn_2b6045f2709c84bb')
						},
						{
							value: '1',
							label: this.$t('i18nn_5dcc73d5e4f71512')
						},
						{
							value: '2',
							label: this.$t('i18nn_03402c5b9fe7cab3')
						},
						{
							value: '3',
							label: this.$t('i18nn_1ee1a0e366f2adba')
						}
					]
				},
				initLinkageData: {
					provinceId: '',
					cityId: '',
					countyId: ''
					// storeId: '',
				},
				//选择
				currentSel: {},
				//表格数据
				//loading,表格数据
				loading: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//分页数据
				pagination1: this.$Utils.defaultPagination(),
				//表格选择的数据
				multipleSelection:[],
				// auditState 审核状态  0-认证未通过 1-认证通过  2-认证审核中
				// perfectState 完善状态  0-未完善 1-完善审核中 2-完善通过 3-完善未通过
				//查询，排序方式
				filterData: {
					level: '',
					updateTimes: '',
					createTimes: '',
					mobile: '',
					partnerPhone: '',
					auditState: '',
					state: '',
					perfectState: '',
					select: 'mobile',
					selectDate: 'updateTime',
					orderBy: 'update_time desc,id_', //排序字段
					sortAsc: 'desc' //desc降序，asc升序
				},
				pickerOptions2: {
					shortcuts: [{
							text: this.$t('i18nn_c688937c592c594f'),
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: this.$t('i18nn_a2db995132295e05'),
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: this.$t('i18nn_502cb8bd057f1605'),
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit('pick', [start, end]);
							}
						}
					]
				},
				updateTimes: '',
				createTimes: '',
				//弹窗
				dialogTableVisible: false,
				dialogHistoryFormVisible: false,
				loading_load: false,
				dialogCaFormVisible: false,
				loading_caform: false,
				dialogFrozenFormVisible: false,
				loading_Frozenform: false,
				CaForm: {
					enterpriseId: '', //企业Id
					enterpriseName: '', //企业名称
					mobile: '', //法人手机号码
					orgCode: '', //组织机构代码
					email: '' //企业邮箱
				},
				CaFormRules: {
					enterpriseId: [{
						required: true,
						message: '企业Id',
						trigger: 'blur'
					}],
					enterpriseName: [{
						required: true,
						message: this.$t('i18nn_9ce5ada346da7c39'),
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: this.$t('i18nn_f41de3a1fa69135a'),
						trigger: 'blur'
					}],
					orgCode: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					email: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}]
				},
				form: {
					id: null,
					enterpriseName: '', // 公司名称
					provinceCode: '', //省
					cityCode: '', //市
					countyCode: '', // 区/县
					enterpriseAddress: '', //详细地址
					enterprisePhone: '', //公司电话
					linkman: '', //公司联系人
					partnerPhone: '', //合伙人电话
					enterpriseEmail: '',
					branchCode: '',
					level: '',
					enterpriseTag: ''
				},
				formRules: {
					enterpriseName: [{
						required: true,
						message: this.$t('i18nn_4622f53565591dbd'),
						trigger: 'blur'
					}],
					provinceCode: [{
						required: true,
						message: this.$t('i18nn_dff1423b58a24b84'),
						trigger: 'blur'
					}],
					cityCode: [{
						required: true,
						message: this.$t('i18nn_ebe921ebb02d5f68'),
						trigger: 'blur'
					}],
					enterpriseAddress: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					enterprisePhone: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					linkman: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					partnerPhone: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					enterpriseEmail: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					branchCode: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					enclosureLicense: [{
						required: true,
						message: this.$t('i18nn_ca736b6e83d86d2c'),
						trigger: 'change'
					}],
					enterpriseTag: [{
						required: true,
						message: this.$t('2ad108ab2c560530'),
						trigger: 'change'
					}]
				},
				tableHistoryHeader: [{
						prop: 'mobile',
						label: this.$t('e82c9fff83ec2d91')
					},
					{
						prop: 'enterpriseName',
						label: this.$t('i18nn_9ce5ada346da7c39')
					},
					{
						prop: 'provinceName',
						label: this.$t('f04d208f84e6c343')
					},
					{
						prop: 'cityName',
						label: this.$t('e05b5d049b64b040')
					},
					{
						prop: 'enterprisePhone',
						label: this.$t('i18nn_558389543b97cb9b')
					},
					{
						prop: 'linkman',
						label: this.$t('i18nn_699a5577be729dde')
					},
					{
						prop: 'partnerName',
						label: this.$t('i18nn_8c3434f7035f8d40')
					},
					{
						prop: 'partnerPhone',
						label: this.$t('i18nn_dcc52d1264a9722b')
					},
					{
						prop: 'updateTime',
						label: this.$t('i18nn_fdc34fd7121f9c48')
					},
					{
						prop: 'updateBy',
						label: this.$t('i18nn_304864e12f05f734')
					}
				],
				enterpriseHistoryId: '',
				tableHistoryData: []
			};
		},
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {},
		methods: {
			//选择省份
			selChangeProvince(data) {
				console.log(this.$t('i18nn_9075502784cc4b3e'), data);
				this.form.provinceCode = data.provinceId; //省份编码
				this.form.cityCode = ''; // 城市编码
				this.form.countyCode = ''; //区县编码
			},
			//选择城市
			selChangeCity(data) {
				console.log(this.$t('i18nn_e6b1c2a1fc07e7d3'), data);
				// this.form.cityCode = "";
				this.form.countyCode = '';
				this.form.cityCode = data.cityId; //城市编码
			},
			//选择区县
			selChangeCounty(data) {
				console.log(this.$t('i18nn_82808948d26efcf1'), data);
				this.form.countyCode = data.countyId; //区县编码
			},
			changeImgUrl1: function(childValue) {
				// childValue就是子组件传过来的值
				this.form.enclosureLicense = childValue;
			},
			changeImgUrl2: function(childValue) {
				// childValue就是子组件传过来的值
				this.form.openAccount = childValue;
			},
			changeImgUrl3: function(childValue) {
				// childValue就是子组件传过来的值
				this.form.certificateAuthorization = childValue;
			},
			changeImgUrl5: function(childValue) {
				// childValue就是子组件传过来的值
				this.form.stockCert = childValue;
			},
			changeImgUrl6: function(childValue) {
				// childValue就是子组件传过来的值
				this.form.taxCert = childValue;
			},
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			//弹窗查看图片样本
			openExImg(showImgType, title) {
				// this.$alert('<img src="' + eximgUrl + '" width="300" height="200"></img>', title, {
				//   dangerouslyUseHTMLString: true
				// });
				this.dialogData.dialogTableVisible = true;
				this.dialogData.dialogTit = title;
				this.dialogData.showImgType = showImgType;
			},
			initData() {
				this.pagination.current_page = 1;
				this.currentSel = {};
				if (this.filterData.selectDate == 'updateTime') {
					this.filterData.createTimes = '';
				} else {
					this.filterData.updateTimes = '';
				}
				// if (this.filterData.select == 'mobile') {
				//   this.filterData.partnerPhone = '';
				// } else {
				//   this.filterData.mobile = '';
				// }
				this.getPageData();
				//部门数据
				// this.getProvinceData();
				// this.getDetData("");
			},

			//后端排序
			sortChange(val, orderBy, sortAsc) {
				console.log('sortChange', val);
				this.filterData.orderBy = orderBy;
				this.filterData.sortAsc = sortAsc;
				this.getPageData();
			},

			//选择
			handleCurrentChange(val) {
				console.log('handleCurrentChange', val);

				// this.$refs.multipleTable2.clearSelection();
				// this.getUserByRole({userId:val.id});
				this.tableData.map(item => {
					if (item.id === val.id) {
						item.selected = true;
					} else {
						item.selected = false;
					}
				});
				this.currentSel = val;
				// this.$refs.multipleTable.toggleRowSelection(this.tableData3[2],true);
			},
			//弹窗更新
			updateVisible(val) {
				this.dialogFormVisible = val;
			},
			//打开新增编辑，弹窗
			openDioalog(formParm) {
				console.log(formParm);
				this.dialogFormVisible = true;
				let form = Object.assign({}, formParm);
				console.log(form);
				if (null === formParm) {
					//新增
					// 重置
					this.resetForm('form');
					// BUG-2：新增调用 resetForm方法后没有将数据重置【临时解决：重新对 form赋值】
					// this.form.id = null;
					// this.form.memberId = this.$store.getters.getUserInfo.buyerId;
					// this.form.consigneeMobile = ""; //  收货人手机号
					// this.form.consigneeName = ""; //收货人
					// this.form.provinceCode = ""; //省份编码
					// this.form.cityCode = ""; // 城市编码
					// this.form.countyCode = ""; //区县编码
					// this.form.consigneeAddress = ""; //收货人详细地址
					// this.form.isDefault = true; //0-非默认地址，1-默认地址
					// this.form.remark = ""; //  备注//非必填
					// this.form.createBy = form.memberId; // 创建人编码//非必填
					this.dialogFormStatus = 0;
					//初始化省市县
					// this.initLinkageData = {
					//   provinceId: '',
					//   cityId: '',
					//   countyId: '',
					// }
					// this.clear(form);
				} else {
					//修改
					// 重置
					this.resetForm('form');

					this.dialogFormStatus = 1;
					// form.enable = form.enable === '1' ? true : false;

					//浅拷贝、对象属性的合并
					// this.form = Object.assign({}, form);
					this.getUserDetData(form.id);
					//初始化省市县
					// this.initLinkageData = {
					//   provinceId: form.provinceCode,
					//   cityId: form.cityCode,
					//   countyId: form.countyCode,
					// }
				}
			},
			// 日志弹窗
			openHistory(formParm) {
				console.log(formParm);
				this.dialogHistoryFormVisible = true;
				let form = Object.assign({}, formParm);
				console.log(form);
				this.enterpriseHistoryId = form.id;
				this.getHistoryPageData(form.id);
			},

			//提交信息
			submitForm(formName) {
				// 验证省市县必输入

				if (!this.form.provinceCode || !this.form.cityCode || !this.form.countyCode) {
					this.$alert('地址省市县数据必填！', this.$t('cc62f4bf31d661e3'), {
						type: 'warning',
						confirmButtonText: this.$t('204ffab8a6e01870')
					});
					return;
				}
				this.$refs[formName].validate(valid => {
					// if (true) {
					if (valid) {
						// alert('submit!');
						let formData = Object.assign({}, this.form);
						//浅拷贝、对象属性的合并
						// this.form = Object.assign({},form);
						if (0 === this.dialogFormStatus) {
							// this.formData.id = null;
							formData.id = null;
							// formData.memberId = this.$store.getters.getUserInfo.buyerId;
							// formData.createBy = this.$store.getters.getUserInfo.id;
							// this.postData(this.$urlConfig.HyUserInfoAdd, formData);
						} else {
							// formData.id = null;
							// formData.memberId = this.$store.getters.getUserInfo.buyerId;
							// formData.createBy = this.$store.getters.getUserInfo.id;
							this.postData(this.$urlConfig.HyBizEnterpriseUpdate, formData);
						}
					} else {
						console.log('error submit!!');
						this.$alert('提交有误,请检查提交数据！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							confirmButtonText: this.$t('204ffab8a6e01870')
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				this[formName] = {};
				// console.log(this.$refs[formName]);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					// this.form.remark = ""; //  备注//非必填
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			},
			//提交信息
			postData(url, formData) {
				let _this = this;
				this.loading = true;

				this.$http
					.post(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							this.dialogFormVisible = false;
							this.getPageData();
							this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
								type: 'success',
								confirmButtonText: this.$t('204ffab8a6e01870')
							});
						} else {
							if (!data.msg) {
								data.msg = '提交失败,请重试';
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning',
								confirmButtonText: this.$t('204ffab8a6e01870')
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							confirmButtonText: this.$t('204ffab8a6e01870')
						});
					});
			},

			//请求分页数据
			getPageData() {
				let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.HyBizEnterpriseList, {
						sortAsc: this.filterData.sortAsc,
						orderBy: this.filterData.orderBy,
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						createTimeBegin: this.filterData.createTimes ? this.filterData.createTimes[0] : '',
						createTimeEnd: this.filterData.createTimes ? this.filterData.createTimes[1] : '',
						updateTimeBegin: this.filterData.updateTimes ? this.filterData.updateTimes[0] : '',
						updateTimeEnd: this.filterData.updateTimes ? this.filterData.updateTimes[1] : '',
						mobileLike: this.filterData.mobile,
						partnerPhoneLike: this.filterData.partnerPhone,
						enterpriseNameLike: this.filterData.enterpriseName,
						auditState: this.filterData.auditState ? this.filterData.auditState : null,
						state: this.filterData.state ? this.filterData.state : null,
						perfectState: this.filterData.perfectState ? this.filterData.perfectState : null,

						level: this.filterData.level ? this.filterData.level : null,
					})
					.then(({
						data
					}) => {
						console.log('企业列表，请求成功');
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('企业列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('企业列表，请求失败');
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},

			getHistoryPageData(bizEnterpriseId) {
				let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.HyBizEnterpriseHistory, {
						bizEnterpriseId: bizEnterpriseId,
						sortAsc: this.filterData.sortAsc,
						orderBy: this.filterData.orderBy,
						offset: (this.pagination1.current_page - 1) * this.pagination1.page_size, //当前页
						limit: this.pagination1.page_size //当前页显示数目
					})
					.then(({
						data
					}) => {
						console.log('企业日志列表，请求成功');
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableHistoryData = data.rows;
							//当前数据总条数
							this.pagination1.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('企业列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('企业日志列表，请求失败');
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},
			//查询详情接口
			getUserDetData(id) {
				// let _this = this;
				this.loading = true;
				this.$http
					.put(this.$urlConfig.HyBizEnterpriseDet, {
						id: id
					})
					.then(({
						data
					}) => {
						console.log('企业详情，请求成功');
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							let form = data.data;
							form.state = form.state === '1' ? true : false;
							//浅拷贝、对象属性的合并
							this.form = Object.assign({}, form);
							this.initLinkageData = {
								provinceId: form.provinceCode,
								cityId: form.cityCode,
								countyId: form.countyCode
							};
							//图片赋值
							// try {
							// 	if (!!this.$refs.hyUpLoadImg1) {
							// 		this.$refs.hyUpLoadImg1.initUrl(form.enclosureLicense);
							// 	}
							// 	if (!!this.$refs.hyUpLoadImg2) {
							// 		this.$refs.hyUpLoadImg2.initUrl(form.openAccount);
							// 	}
							// 	if (!!this.$refs.hyUpLoadImg3) {
							// 		this.$refs.hyUpLoadImg3.initUrl(form.certificateAuthorization);
							// 	}
							// 	if (!!this.$refs.hyUpLoadImg5) {
							// 		this.$refs.hyUpLoadImg5.initUrl(form.stockCert);
							// 	}
							// 	if (!!this.$refs.hyUpLoadImg6) {
							// 		this.$refs.hyUpLoadImg6.initUrl(form.taxCert);
							// 	}
							// } catch (e) {
							// 	console.log(e);
							// }
							// this.$message.success('用户详情，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_35da7aebbfd476d0'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('用户详情，请求失败');
						this.$message.error('详情数据，请求失败！');
						this.loading = false;
					});
			},

			/*//删除操作
			delPageAction(dataParm) {
			  this.$confirm(this.$t('i18nn_18a47a2171c01d1c') + dataParm.userName + '吗?', this.$t('daaaeb1b7b22b126'), {
			    confirmButtonText: this.$t('204ffab8a6e01870'),
			    cancelButtonText: this.$t('4b3340f7872b411f'),
			    type: 'warning'
			  }).then(() => {
			    this.delPageData(dataParm);
			  }).catch(() => {

			  });
			},
			//删除特定数据
			delPageData(dataParm) {
			  let _this = this;
			  console.log(dataParm);
			  this.loading_load = true;
			  this.$http.delete(this.$urlConfig.HyBizUserInfoDet, { data: { id: dataParm.id } })
			    .then(({ data }) => {
			      console.log("删除，请求成功");
			      console.log(data);
			      this.loading_load = false;
			      if (200 == data.code) {
			        this.$alert('恭喜,删除成功！', this.$t('cc62f4bf31d661e3'), {
			          type: 'success',
			          confirmButtonText: this.$t('204ffab8a6e01870'),
			        });
			        this.getPageData();
			      } else {
			        if (!data.msg) {
			          data.msg = "删除失败,请重试";
			        }
			        this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
			          type: 'warning',
			          confirmButtonText: this.$t('204ffab8a6e01870'),
			        });
			      }
			    })
			    .catch((error) => {
			      console.log(error);
			      console.log("删除，请求失败");
			      this.loading_load = false;
			      this.$alert('删除失败,请重试！', this.$t('cc62f4bf31d661e3'), {
			        type: 'warning',
			        confirmButtonText: this.$t('204ffab8a6e01870'),
			      });
			    });
			},*/
			//打开解冻弹窗
			OpenFrozenAction() {
				let currentSel = this.currentSel;
				if (!currentSel.enterpriseName) {
					this.$message.warning(this.$t('7b80e66b535a3732'));
					return;
				}

				this.dialogFrozenFormVisible = true;
			},
			//申请企业ca证书
			ApplyEnCaAction() {
				// this.updatePageData(this.$urlConfig.HyBizEnterpriseCA, {
				//   id: currentSel.userId ,callingParty: '3',auditingState: "2", versionNo:currentSel.versionNo
				// }, 'msg');
				// let msg = "申请CA证书";
				let currentSel = this.currentSel;
				if (!currentSel.enterpriseName) {
					this.$message.warning(this.$t('7b80e66b535a3732'));
					return;
				}
				console.log(currentSel);

				this.CaForm = {
					enterpriseId: currentSel.id,
					enterpriseName: currentSel.enterpriseName,
					mobile: currentSel.mobile,
					orgCode: currentSel.branchCode,
					email: currentSel.enterpriseEmail
				};

				this.dialogCaFormVisible = true;
				// return;
				// this.$confirm( currentSel.enterpriseName+'  '+msg +  '吗?', this.$t('daaaeb1b7b22b126'), {
				//   confirmButtonText: this.$t('204ffab8a6e01870'),
				//   cancelButtonText: this.$t('4b3340f7872b411f'),
				//   type: 'warning'
				// }).then(() => {

				// }).catch(() => {

				// });
			},
			//提交ca证书
			applyCASubmitForm(formName) {
				// 验证省市县必输入

				// if (!this.form.provinceCode || !this.form.cityCode || !this.form.countyCode) {
				//   this.$alert('地址省市县数据必填！', this.$t('cc62f4bf31d661e3'), {
				//     type: 'warning',
				//     confirmButtonText: this.$t('204ffab8a6e01870'),
				//   });
				//   return;
				// }
				this.$refs[formName].validate(valid => {
					// if (true) {
					if (valid) {
						// alert('submit!');
						let formData = Object.assign({}, this.CaForm);
						let msg = '申请CA证书';
						this.updatePageData(
							this.$urlConfig.HyBizEnterpriseCA, {
								enterpriseId: formData.enterpriseId,
								enterpriseName: formData.enterpriseName,
								mobile: formData.mobile,
								orgCode: formData.orgCode,
								email: formData.email
							},
							msg
						);
					} else {
						console.log('error submit!!');
						this.$alert('提交有误,请检查提交数据！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							confirmButtonText: this.$t('204ffab8a6e01870')
						});
						return false;
					}
				});
			},
			//解冻，冻结状态
			applyFrozenSubmitForm(state) {
				//状态 0封禁 1正常 2一般冻结 3永久冻结
				let msg = '';
				if ('1' === state) {
					msg = this.$t('i18nn_85da697a473926e3');
				} else if ('2' === state) {
					msg = this.$t('i18nn_8c919e34ddb841f3');
				} else if ('3' === state) {
					msg = this.$t('i18nn_15585d51ec229bda');
				} else {
					this.$alert('未知操作状态，请联系管理员。', this.$t('cc62f4bf31d661e3'), {
						type: 'warning',
						confirmButtonText: this.$t('204ffab8a6e01870')
					});
					return;
				}
				let currentSel = this.currentSel;
				// if(!currentSel.enterpriseName){
				//   this.$message.warning(this.$t('7b80e66b535a3732'));
				//   return;
				// }
				this.$confirm(currentSel.enterpriseName + '  ' + msg + '吗?', this.$t('daaaeb1b7b22b126'), {
						confirmButtonText: this.$t('204ffab8a6e01870'),
						cancelButtonText: this.$t('4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						this.updatePageData(
							this.$urlConfig.HyBizEnterpriseFrozen, {
								enterpriseId: currentSel.id,
								frozenType: state
							},
							msg
						);
					})
					.catch(() => {});
			},
			//审核通过
			AuditPassedAction() {
				let msg = this.$t('i18nn_993c4e2a71557fe7');
				let currentSel = this.currentSel;
				if (!currentSel.enterpriseName) {
					this.$message.warning(this.$t('7b80e66b535a3732'));
					return;
				}
				this.$confirm(currentSel.enterpriseName + '  ' + msg + '吗?', this.$t('daaaeb1b7b22b126'), {
						confirmButtonText: this.$t('204ffab8a6e01870'),
						cancelButtonText: this.$t('4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						this.updatePageData(this.$urlConfig.HyBizEnterpriseAudit, {
							id: currentSel.userId,
							callingParty: '3',
							auditingState: '2',
							versionNo: currentSel.versionNo
						}, msg);
					})
					.catch(() => {});
			},
			//审核不通过
			AuditNoPassedAction() {
				let msg = this.$t('i18nn_7fe92f41627d9a70');
				let currentSel = this.currentSel;
				if (!currentSel.enterpriseName) {
					this.$message.warning(this.$t('7b80e66b535a3732'));
					return;
				}
				this.$confirm(currentSel.enterpriseName + '  ' + msg + '吗?', this.$t('daaaeb1b7b22b126'), {
						confirmButtonText: this.$t('204ffab8a6e01870'),
						cancelButtonText: this.$t('4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						this.updatePageData(this.$urlConfig.HyBizEnterpriseAudit, {
							id: currentSel.userId,
							callingParty: '3',
							auditingState: '3',
							versionNo: currentSel.versionNo
						}, msg);
					})
					.catch(() => {});
			},

			//更新特定数据
			updatePageData(url, dataParm, msg) {
				let _this = this;
				this.loading = true;
				//浅拷贝、对象属性的合并
				let dataParmAss = Object.assign({}, dataParm);
				this.$http
					.post(url, dataParmAss)
					.then(({
						data
					}) => {
						this.loading = false;
						console.log(msg + '，请求成功');
						console.log(data);
						if (200 == data.code) {
							this.$alert(msg + '成功！', this.$t('cc62f4bf31d661e3'), {
								type: 'success',
								confirmButtonText: this.$t('204ffab8a6e01870')
							});
							this.dialogCaFormVisible = false;
							this.dialogFrozenFormVisible = false;
							this.getPageData();
						} else {
							this.$alert(data.msg ? data.msg : msg + '失败！', this.$t('cc62f4bf31d661e3'), {
								type: 'warning',
								confirmButtonText: this.$t('204ffab8a6e01870')
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(msg + '，请求失败');
						this.loading = false;
						this.$alert(msg + '失败,请重试！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							confirmButtonText: this.$t('204ffab8a6e01870')
						});
					});
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/
</style>